import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import "@fontsource/poppins/100.css";
import openness from "../images/openn.webp";
import cons from "../images/consci.webp";
import extra from "../images/extrav.webp";
import agree from "../images/agreea.webp";
import neuro from "../images/neurot.webp";
import big5back from "../images/big5back.webp";
import diagram from "../images/diagram.webp";
import toggle from "../images/toggle.svg";
import Scroll from '../components/scroll';
import { Helmet } from "react-helmet";
import i18next from "i18next";

// markup
const AboutPage = () => {

  const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);
  
  return (
    <div className="">
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
        <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
        <meta name="author" content="Rejoice" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{i18next.t("about.header.title")}</title>
      </Helmet>
      <Header />
      <Scroll showBelow={250} />
      <div className="text-center" id="aboutDesktop">
        <h2 className="dmsans mt-12 pinkperfect text-3xl font-normal">{i18next.t("about.block1.title")}</h2>
        <p className="w-3/4 lg:w-1/2 md:text-center text-justify mt-4 mx-auto leading-10">{i18next.t("about.block1.text")}</p>
      </div>
      <div className="mr-12 mt-12 md:mt-24 ml-12 relative mx-auto lg:flex hidden justify-center flex-wrap flex-row">
        <img src={big5back} alt="big5back" className="absolute hidden top-15" />
        <div className="w-2/5 z-10 relative ml-10 mr-10">
          <div className="text-center rounded-3xl ml-10 mr-10 mx-auto justify-center content-center shadow-perfect">
            <img src={openness} alt="openness" className="w-full rounded-t-3xl mx-auto" />
            <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block2.title")}</h3>
            <p className="w-10/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block2.text")}</p>
          </div>
          <div className="mt-4 flex justify-center align-center w-11/12 mx-auto">
            <svg width="657" height="59" viewBox="0 0 657 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_1_147)">
                <rect x="4" width="649" height="51" rx="25.5" fill="url(#paint0_radial_1_147)" />
              </g>
              <defs>
                <filter id="filter0_d_1_147" x="0" y="0" width="657" height="59" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0.92549 0 0 0 0 0.894118 0 0 0 1 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_147" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_147" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_1_147" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-36.5 12.6933) rotate(3.17992) scale(690.563 477.147)">
                  <stop stopColor="#88347F" />
                  <stop offset="1" stopColor="#F7E4F7" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="w-2/5 relative z-50 text-center rounded-3xl ml-10 mr-10 mx-auto justify-center content-center shadow-perfect">
          <img src={cons} alt="conscientiousness" className="w-full rounded-t-3xl relative z-50 mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect relative z-50">{i18next.t("about.block3.title")}</h3>
          <p className="w-10/12 text-justify mt-4 mx-auto leading-10 relative z-50">{i18next.t("about.block3.text")}</p>
        </div>
        <div className="w-2/5 relative z-50 text-center rounded-3xl mt-10 ml-10 mr-10 mx-auto justify-center content-center shadow-perfect">
          <img src={extra} alt="extraversion" className="w-full rounded-t-3xl mx-auto relative" />
          <h3 className="dmsans font-bold relative text-xl pinkperfect ">{i18next.t("about.block4.title")}</h3>
          <p className="w-10/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block4.text")}</p>
        </div>
        <div className="w-2/5 relative z-50 text-center rounded-3xl mt-10 ml-10 mr-10 mx-auto justify-center content-center shadow-perfect">
          <img src={agree} alt="agreeableness" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect">{i18next.t("about.block5.title")}</h3>
          <p className="w-10/12 text-justify  mt-4 mx-auto leading-10">{i18next.t("about.block5.text")}</p>
        </div>
        <div className="w-full relative z-50 mt-12 mb-6 flex align-center justify-center">
          <svg width="1273" height="59" viewBox="0 0 1273 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1_148)">
              <rect x="4" width="1265" height="51" rx="25.5" fill="url(#paint0_radial_1_148)" />
            </g>
            <defs>
              <filter id="filter0_d_1_148" x="0" y="0" width="1273" height="59" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0.92549 0 0 0 0 0.894118 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_148" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_148" result="shape" />
              </filter>
              <radialGradient id="paint0_radial_1_148" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1235.5 51.0002) rotate(-177.575) scale(1205.58 5022.39)">
                <stop stopColor="#88347F" />
                <stop offset="1" stopColor="#F7E4F7" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="w-full relative z-50 flex flex-row text-center rounded-3xl xl:ml-12 xl:mr-12 mx-auto justify-center content-center shadow-perfect">
          <div className="w-1/2">
            <img src={neuro} alt="neuroticism" className="w-full rounded-l-3xl mx-auto" />
          </div>
          <div className="w-1/2 text-center flex justify-center items-center">
            <div className="h-50">
              <h3 className="dmsans mx-auto font-bold text-xl pinkperfect ">{i18next.t("about.block6.title")}</h3>
              <p className="w-10/12 text-justify mt-6 mx-auto leading-10">{i18next.t("about.block6.text")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container flex lg:hidden flex-col mx-auto mt-12">
        <div className="w-4/5 shadow-perfect text-center rounded-3xl mx-auto justify-center content-center">
          <img src={openness} alt="openness" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block2.title")}</h3>
          <p className="w-11/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block2.text")}</p>
        </div>
        <div className="w-4/5 shadow-perfect text-center mt-12 rounded-3xl mx-auto justify-center content-center">
          <img src={cons} alt="Conscientiousness" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block3.title")}</h3>
          <p className="w-11/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block3.text")}</p>
        </div>
        <div className="w-4/5 shadow-perfect text-center mt-12 rounded-3xl mx-auto justify-center content-center">
          <img src={extra} alt="extraversion" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block4.title")}</h3>
          <p className="w-11/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block4.text")}</p>
        </div>
        <div className="w-4/5 shadow-perfect text-center mt-12 rounded-3xl mx-auto justify-center content-center">
          <img src={agree} alt="agreeableness" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block5.title")}</h3>
          <p className="w-11/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block5.text")}</p>
        </div>
        <div className="w-4/5 shadow-perfect text-center mt-12 rounded-3xl mx-auto justify-center content-center">
          <img src={neuro} alt="neuroticism" className="w-full rounded-t-3xl mx-auto" />
          <h3 className="dmsans font-bold text-xl pinkperfect ">{i18next.t("about.block6.title")}</h3>
          <p className="w-11/12 text-justify mt-4 mx-auto leading-10">{i18next.t("about.block6.text")}</p>
        </div>
      </div>
      <div className="relative px-4 mt-10 sm:px-2 lg:px-8">
        <div className="text-lg w-full md:w-3/4 mx-auto">
          <p className="w-11/12 text-xl mx-auto text-justify text-gray-500 leading-8">{i18next.t("about.block7.text.p1")}</p>
          <p className="w-11/12 text-xl mt-6 mx-auto font-bold text-justify text-pink-500 leading-8">{i18next.t("about.block7.text.p2")}</p>
        </div>
      </div>
      <div className="container mt-12 flex flex-col lg:flex-row mx-auto">

        <div className="lg:w-1/2 justify-center align-center mx-auto text-center">
          <h2 className="dmsans mt-12 pinkperfect text-3xl font-normal">{i18next.t("about.block8.title")}</h2>
          <p className="w-3/4 lg:w-1/2 mt-4 mx-auto leading-10">{i18next.t("about.block8.text")}</p>
        </div>
        <div className="lg:w-1/2 justify-center align-center mx-auto text-center">
          <h2 className="dmsans mt-12 pinkperfect text-3xl font-normal">{i18next.t("about.block9.title")}</h2>
          <p className="w-3/4 lg:w-1/2 mt-4 mx-auto leading-10">{i18next.t("about.block9.text")}</p>
        </div>
      </div>
      <div className="container mx-auto mt-24 text-center">
        <h2 className="dmsans mt-12 pinkperfect text-3xl tracking-wide font-bold">{i18next.t("about.block10.title")}</h2>
        <img src={diagram} alt="diagram" className="w-full lg:w-4/5 2xl:w-2/5 mx-auto" />
        <p className="w-3/4 mt-4 mx-auto text-xl leading-10">{i18next.t("about.block10.text")}</p>
      </div>
      <div className="container mx-auto mt-24 text-center">
        <h2 className="dmsans mt-12 pinkperfect text-3xl tracking-wide font-bold">{i18next.t("about.block11.title")}</h2>
        <p className="w-3/4 mt-4 mx-auto text-xl leading-10">{i18next.t("about.block11.text")}</p>
      </div>
      <div className="container hidden mx-auto mt-24 text-center">
        <div className="rounded-2xl p-6 faqbutton w-1/2 mx-auto">
          <h2 className="dmsans text-3xl tracking-wide text-white font-bold">FAQ</h2>
        </div>
        <div className="rounded-3xl faqbackground flex flex-row flex-wrap justify-center items-center pt-12 pb-12 mt-6 w-3/4 mx-auto">
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">How is Rejoice different?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
            <p className=" float-right">Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor</p>
          </div>
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">What are the different pricing?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
          </div>
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">How do I know that my information is safe?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
          </div>
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">Are you hiring?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
          </div>
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">Some question?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
          </div>
          <div className="w-45 m-3 p-10 rounded-2xl shadow-faq-box">
            <h2 className="dmsans darkpink text-xl font-medium float-left w-3/4">Some question?</h2>
            <img src={toggle} alt="toggle" className=" float-right" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
